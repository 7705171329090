import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton, Tab, Tabs } from '@material-ui/core';
import { isIos } from 'common-ui';
import { browserHistory } from 'react-router';
import MenuIcon from '@material-ui/icons/Menu';

import AppBar from '../components/app-bar';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';

const styles = {
  instructionImage: {
    marginBottom: 15,
    marginTop: 15,
    maxWidth: 300,
  },
  instructionImageSmall: {
    marginBottom: 15,
    marginTop: 15,
    maxWidth: 75,
  },
  instructionListItem: {
    marginBottom: 15,
  },
  pageContent: {
    fontSize: fontSizing.body,
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageTitle: {
    fontSize: fontSizing.body,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pageWrapper: {
    background: colors.white,
    minHeight: '100vh',
    paddingBottom: 20,
  },
  tabBar: {
    marginBottom: 10,
    marginTop: 10,
    '& .MuiTab-wrapper': {
      fontSize: fontSizing.body,
    },
  },
};

class CoreVitalTurnOnPermissions extends React.Component {
  constructor(props) {
    super(props);

    const selectedTab = isIos ? 0 : 1;

    this.state = {
      selectedTab,
    };
  }

  handleChangeSelectedTab = (event, newValue) => {
    this.setState({ selectedTab: newValue });
  }

  render() {
    const { classes, router } = this.props;
    const { selectedTab } = this.state;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          <div className={classes.pageTitle}>How to give IVC App permission to use your camera:</div>
          <Tabs
            variant="fullWidth"
            value={selectedTab}
            onChange={this.handleChangeSelectedTab}
            classes={{ root: classes.tabBar }}
          >
            <Tab label="iOS" />
            <Tab label="Android" />
          </Tabs>
          <div hidden={selectedTab !== 0}>
            <ol>
              <li className={classes.instructionListItem}>
                <div>Tap on the <strong>Settings</strong> icon</div>
                <img src="/img/ivc-ios-permissions-01.png" className={classes.instructionImageSmall} alt="ios settings icon" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Scroll down and tap <strong>Safari</strong></div>
                <img src="/img/ivc-ios-permissions-02.jpg" className={classes.instructionImage} alt="safari menu item in ios settings" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Scroll down until you see <strong>Camera</strong></div>
                <img src="/img/ivc-ios-permissions-03.jpg" className={classes.instructionImage} alt="camera menu item in safari settings" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Tap <strong>Camera</strong></div>
              </li>
              <li className={classes.instructionListItem}>
                <div>Tap <strong>Ask</strong> or <strong>Allow</strong></div>
                <img src="/img/ivc-ios-permissions-04.jpg" className={classes.instructionImage} alt="ask menu item in camera settings" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Return to your messaging app and tap the link to launch the IVC App</div>
                <img src="/img/ivc-ios-permissions-08.png" className={classes.instructionImage} alt="text message with link to ivc app" />
              </li>
            </ol>
          </div>
          <div hidden={selectedTab !== 1}>
            <ol>
              <li className={classes.instructionListItem}>
                <div>Tap on the <strong>Settings</strong> icon</div>
                <img src="/img/ivc-android-permissions-01.png" className={classes.instructionImageSmall} alt="android settings icon" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Scroll down to <strong>Apps</strong> and tap on it</div>
                <img src="/img/ivc-android-permissions-02.jpg" className={classes.instructionImage} alt="apps section in settings" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Click on <strong>Permission Manager</strong></div>
                <img src="/img/ivc-android-permissions-03.jpg" className={classes.instructionImage} alt="permissions manager in apps section" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Locate <strong>Camera</strong></div>
                <img src="/img/ivc-android-permissions-04.jpg" className={classes.instructionImage} alt="campera options in permissions manager" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Tap on your browser application</div>
                <img src="/img/ivc-android-permissions-05.jpg" className={classes.instructionImage} alt="chrome option in camera settings" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Tap on <strong>Allow</strong>.</div>
                <img src="/img/ivc-android-permissions-06.jpg" className={classes.instructionImage} alt="ask and allow menu items in camera permissions" />
              </li>
              <li className={classes.instructionListItem}>
                <div>Return to your messaging app and tap the link to launch the IVC App.</div>
                <img src="/img/ivc-ios-permissions-08.png" className={classes.instructionImage} alt="text message with link to ivc app" />
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

CoreVitalTurnOnPermissions.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalTurnOnPermissions);
