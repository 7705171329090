import { createMuiTheme } from '@material-ui/core/styles';

import { colors } from './styles';

export const muiMindsetNextTheme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primaryColor,
    },
    secondary: {
      main: colors.secondaryColor,
    },
  },
  typography: {
    body1: {
      fontSize: 22,
    },
    h1: {
      fontSize: 44,
    },
    h2: {
      fontSize: 33,
    },
    h3: {
      fontSize: 27,
    },
  },
});
