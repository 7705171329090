import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { find } from 'lodash';

import HiddenContent from '../components/hidden-content';
import Page from './page';
import Layout from '../layouts/common';
import Continue from '../components/continue';
import { colors, desktopMaxWidth } from '../lib/styles';
import { apiFetch, makeShadowOptions } from '../lib/fetch';
import { clearPros } from '../state/pro-forms';
import notificationTracks from '../notification-pages';
import DisableSessionTimeout from '../components/disable-session-timeout';
import { removeProInNewTabListener } from '../lib/pro-in-new-tab';

const baseStyles = {
  btn: {
    maxWidth: '27rem',
    marginTop: '10rem',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0px auto',
    maxWidth: desktopMaxWidth,
  },
  greenCheckMark: {
    marginTop: '4rem',
    marginBottom: '3rem',
    width: '8rem',
    height: '8rem',
  },
  paragraph: {
    color: colors.primaryColor,
    fontSize: '2rem',
  },
};
class InfoUpdatedSuccessfully extends Page {
  componentDidMount() {
    const userId = this.props.user.id;
    const notificationId = this.props.location.query.notification_id

    if (userId && notificationId) {
      const options = makeShadowOptions({ dismissed: true }, this.props.user.token);
      apiFetch(`/users/${userId}/notifications/${notificationId}`, options)
    }

    this.backButtonHandler = () => {
      window.history.go(1);
    };
    window.addEventListener('popstate', this.backButtonHandler, true);

    this.props.clearPros();
    removeProInNewTabListener();
  }

  render() {
    return (
      <Layout>
        <DisableSessionTimeout />
        <div style={baseStyles.container}>
          <img
            src="/img/GreenCheckmarkCircle.png"
            style={baseStyles.greenCheckMark}
            alt="Green check mark"
          />
          <div style={baseStyles.paragraph}>{this.props.text || 'Info Updated Successfully!'}</div>
          <HiddenContent
            hidden={!this.props.loggedIn}
          >
            <Continue
              onClick={this.forward}
              btnStyle={baseStyles.btn}
            />
          </HiddenContent>
        </div>
      </Layout>
    );
  }
}

InfoUpdatedSuccessfully.defaultProps = {
  text: 'Info Updated Successfully!',
};

InfoUpdatedSuccessfully.propTypes = {
  text: PropTypes.node,
  loggedIn: PropTypes.bool.isRequired,
};

function mapStateToProps(state, ownProps) {
  const { user } = state;
  const { notificationType: type } = ownProps.routeParams;

  const defaults = find(notificationTracks, { type });
  const futureProps = { loggedIn: false, user, type };
  if (user.roles && user.roles.length && user.roles.includes('PATIENT') && user.scope !== 'SHADOW_PATIENT') {
    futureProps.loggedIn = true;
    if (defaults) {
      futureProps.text = defaults.loggedInSuccessText;
    }
  } else if (defaults) {
    futureProps.text = defaults.loggedOutSuccessText;
  }
  return futureProps;
}

export default connect(mapStateToProps, { clearPros })(InfoUpdatedSuccessfully);
