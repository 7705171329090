import React from 'react';
import PropTypes from 'prop-types';
import { IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import Logo from '../components/logo';
import { submitPRO } from '../state/pro-forms';
import QuestionLayout from '../layouts/question';

const styles = {
  bodyText: {
    marginTop: 20,
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  pageContainer: {
    backgroundColor: colors.questionBackground,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
    backgroundColor: colors.questionBackground,
  },
  verticalAlignBottom: {
    verticalAlign: 'bottom',
  },
};

class VitalCoreBenchHr5 extends Page {
  render() {
    const { classes } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={() => { this.forwardWithQuery(this.props.location.query); }}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <div className={classes.pageContainer}>
          <div className={classes.pageContentContainer}>
            <AppBar
              headerNode="Instructions"
              backButtonOnClick={() => this.props.router.goBack()}
              rightNode={(
                <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
            />
            <LinearProgress
              variant="determinate"
              value={(this.getCurrentPage() / this.getTotalPages()) * 100}
              classes={{ bar: classes.linearProgress }}
            />
            <div
              className={classes.pageContent}
            >
              <Logo />
              <div className={classes.header}>
                User Manual
              </div>
              <p>
                <i>Click the</i> <MenuIcon className={classes.verticalAlignBottom} fontSize="large" /> <i>located in the upper right-hand corner to access the User Manual at any time.</i>
              </p>
            </div>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

VitalCoreBenchHr5.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { user } = state;

  return { user };
};

export default connect(mapStateToProps, { submitPRO })(withStyles(styles)(VitalCoreBenchHr5));
