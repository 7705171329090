import React, { div } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';
import { User } from '../lib/icons';

import Page from './page';
import { desktopMaxWidth } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  button: {
    margin: 5,
    width: 150,
    height: 40,
    fontSize: 16,
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  mainSection: {
    flexGrow: 1,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
  },
  rootContainer: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '1.75rem',
  },
  nextBtn: {
    boxSizing: 'border-box',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    padding: 20,
    width: '100%',
  },
};

class VitalCoreBenchHrFacial extends Page {
  constructor(props) {
    super(props);

    this.state = {
      facialHair: null,
      facialTattoos: null,
      glasses: null,
    };
  }

  handleContinue = async () => {
    const { updatePRO, location: { query } } = this.props;

    updatePRO({
      type: 'vitalCoreBenchHr',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(query);
  }

  render() {
    const { facialHair, facialTattoos, glasses } = this.state;
    const { classes } = this.props;
    return (
      <div className={classes.rootContainer}>
        <div className={classes.mainSection}>
          <AppBar
            headerNode="Patient Information"
            noLeftNode={true}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
          />
          <div className={classes.pageContent}>
            <div className={classes.iconContainer}>
              <div className={classes.iconWrapper}>
                <User />
              </div>
            </div>

            <div className={classes.titleGroup}>
              <p className={classes.text}>Facial Hair</p>
              <div className={classes.buttonGroup}>
                <Button className={classes.button} variant="contained" color={facialHair ? 'primary' : 'default'} onClick={() => this.setState({ facialHair: true })}>Yes</Button>
                <Button className={classes.button} variant="contained" color={facialHair === false ? 'primary' : 'default'} onClick={() => this.setState({ facialHair: false })}>No</Button>
              </div>
            </div>

            <div className={classes.titleGroup}>
              <p className={classes.text}>Facial Tattoos</p>
              <div className={classes.buttonGroup}>
                <Button className={classes.button} variant="contained" color={facialTattoos ? 'primary' : 'default'} onClick={() => this.setState({ facialTattoos: true })}>Yes</Button>
                <Button className={classes.button} variant="contained" color={facialTattoos === false ? 'primary' : 'default'} onClick={() => this.setState({ facialTattoos: false })}>No</Button>
              </div>
            </div>

            <div className={classes.titleGroup}>
              <p className={classes.text}>Glasses</p>
              <div className={classes.buttonGroup}>
                <Button className={classes.button} variant="contained" color={glasses ? 'primary' : 'default'} onClick={() => this.setState({ glasses: true })}>Yes</Button>
                <Button className={classes.button} variant="contained" color={glasses === false ? 'primary' : 'default'} onClick={() => this.setState({ glasses: false })}>No</Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.nextBtn}>
          <Continue
            disabled={facialHair === null || facialTattoos === null || glasses === null}
            text="Next"
            onClick={this.handleContinue}
            btnStyle={{ width: '100%' }}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { vitalCoreBenchHr } } = state;
  return { user, vitalCoreBenchHr };
}

VitalCoreBenchHrFacial.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(VitalCoreBenchHrFacial));
