import { makeDuck, makeActionCreator } from 'cooldux';
import { omit } from 'lodash';

import { apiFetch, makeShadowOptions } from '../lib/fetch';

export const updatePRO = makeActionCreator('updatePRO');
export const update = makeActionCreator('update');
export const createProData = makeActionCreator('createProData');
export const clearPros = makeActionCreator('clearPros');

const PROs = makeDuck({
  submitPRO: (ids, body, user) => {
    const reqOptions = makeShadowOptions(body, user.token);
    return apiFetch(`/users/${ids.userId}/${user.token ? 'pro_lite_submissions' : 'pro_submissions'}/${ids.id}`, reqOptions);
  },
}, { throwErrors: true });

export const { submitPRO } = PROs;

const initialState = {
  vitalCore: [],
  vitalCoreBenchHr: [],
};

function proForms(state = initialState, { type, payload }) {
  switch (type) {
    case createProData.type: {
      return {
        ...state,
        type: payload.type,
        id: payload.id,
      };
    }
    case updatePRO.type: {
      const pro = [...state[payload.type]];
      pro[payload.position] = payload.value;
      return { ...state, [payload.type]: pro };
    }
    case update.type: {
      let newState = { ...state };
      const initialKeys = Object.keys(initialState);
      newState = omit(newState, initialKeys);
      Object.keys(payload).forEach((key) => {
        newState[key] = payload[key];
      });
      return newState;
    }
    case clearPros.type: {
      return initialState;
    }
    default:
      return state;
  }
}

export default {
  proSubmit: PROs.reducerCombined,
  proForms,
};
