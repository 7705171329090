import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { User } from '../lib/icons';

import Page from './page';
import { desktopMaxWidth } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  formControlLabelRoot: {
    alignItems: 'start',
    marginTop: 5,
    '& .MuiSvgIcon-root': {
      height: 20,
      width: 20,
    },
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
    marginTop: 10,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
  },
  radio: {
    paddingTop: 4,
  },
  radioLabel: {
    fontSize: '2rem',
  },
  titleGroup: {
    margin: 25,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    padding: 20,
  },
};

class VitalCoreBenchHrInfo extends Page {
  constructor(props) {
    super(props);

    this.state = {
      sex: null,
      ethnicity: null,
      race: null,
    };
  }

  handleChange = (e) => {
    const { value } = e.target;
    this.setState({
      sex: value,
    });
  };

  handleChangeEthnicity = (e) => {
    const { value } = e.target;
    this.setState({
      ethnicity: value,
    });
  };

  handleChangeRace = (e) => {
    const { value } = e.target;
    this.setState({
      race: value,
    });
  };

  handleContinue = async () => {
    const { updatePRO, location: { query } } = this.props;

    updatePRO({
      type: 'vitalCoreBenchHr',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(query);
  }

  render() {
    const { classes } = this.props;
    const { sex, ethnicity, race } = this.state;

    return (
      <Fragment>
        <AppBar
          headerNode="Patient Information"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.pageContent}>
          <div className={classes.iconContainer}>
            <div className={classes.iconWrapper}>
              <User />
            </div>
          </div>

        <div className={classes.titleGroup}>
          <FormControl component="fieldset">
            <FormLabel className={classes.text} component="legend">Sex</FormLabel>
            <RadioGroup aria-label="gender" name="gender" value={sex} onChange={this.handleChange}>
              <FormControlLabel
                value="female"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Female"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="male"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Male"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
              <FormControlLabel
                value="other"
                control={<Radio classes={{ root: classes.radio }} />}
                label="Other"
                classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
              />
            </RadioGroup>
          </FormControl>
        </div>

          <div className={classes.titleGroup}>
            <FormControl component="fieldset">
              <FormLabel className={classes.text} component="legend">Ethnicity</FormLabel>
              <RadioGroup aria-label="ethnicity" name="ethnicity" value={ethnicity} onChange={this.handleChangeEthnicity}>
                <FormControlLabel
                  value="Hispanic or Latino"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="Hispanic or Latino"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="Non-Hispanic or Latino"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="Non-Hispanic or Latino"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className={classes.titleGroup}>
            <FormControl component="fieldset">
              <FormLabel className={classes.text} component="legend">Race</FormLabel>
              <RadioGroup aria-label="race" name="race" value={race} onChange={this.handleChangeRace}>
                <FormControlLabel
                  value="American Indian or Alaskan Native"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="American Indian or Alaskan Native"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="Asian"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="Asian"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="Black or African American"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="Black or African American"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="Native Hawaiian or Other Pacific Islander"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="Native Hawaiian or Other Pacific Islander"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="White"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="White"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
                <FormControlLabel
                  value="Other"
                  control={<Radio classes={{ root: classes.radio }} />}
                  label="Other"
                  classes={{ root: classes.formControlLabelRoot, label: classes.radioLabel }}
                />
              </RadioGroup>
            </FormControl>
          </div>

          <div className={classes.nextBtn}>
            <Continue
              disabled={(sex === null) || (ethnicity === null) || (race === null)}
              text="Next"
              onClick={this.handleContinue}
              btnStyle={{ width: '100%' }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { vitalCoreBenchHr } } = state;
  return { user, vitalCoreBenchHr };
}

VitalCoreBenchHrInfo.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(VitalCoreBenchHrInfo));
