import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';

import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { CircleExclamationSolid } from '../lib/icons';
import Continue from '../components/continue';
import { logoutWithoutRedirect } from '../state/user';
import { throttledReset } from '../initializers/activity';
import { removeProInNewTabListener } from '../lib/pro-in-new-tab';
import AppBar from '../components/app-bar';
import { clearPros } from '../state/pro-forms';

const styles = {
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  exclamationWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
    '& path': {
      fill: colors.errorRed,
    },
  },
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
  },
  iconWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: desktopMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',
    textAlign: 'center',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
  },
};

class Timeout extends React.Component {
  constructor(props) {
    super(props);

    this.props.clearPros();

    const {
      header,
      hideRetry,
      icon,
      messageOne,
      messageTwo,
      pageTitle,
      onRetry,
      notificationAuthLogoutRoute,
    } = props.errorScreens;

    this.state = {
      header,
      hideRetry,
      icon,
      messageOne,
      messageTwo,
      pageTitle,
      onRetry,
      notificationAuthLogoutRoute,
    };

    if (props.loggedIn && !props.errorScreens.noLogout) {
      props.logoutWithoutRedirect();
    }

    this.backButtonHandler = () => {
      window.history.go(1);
    };
    window.addEventListener('popstate', this.backButtonHandler, true);

    this.activityTimer = setInterval(throttledReset, 10000);
    removeProInNewTabListener();
  }

  componentWillUnmount = () => {
    clearInterval(this.activityTimer);
  }

  handleClickContinue = () => {
    const { router } = this.props;
    const { notificationAuthLogoutRoute } = this.state;

    window.removeEventListener('popstate', this.backButtonHandler, true);

    if (notificationAuthLogoutRoute) {
      router.push(notificationAuthLogoutRoute);
      return;
    }

    router.push('/login');
  }

  handleClickMenuIcon = () => {
    const { router } = this.props;
    window.removeEventListener('popstate', this.backButtonHandler, true);

    router.push('/core-vital-user-manual-menu');
  }

  render() {
    const { classes } = this.props;

    const {
      header,
      hideRetry,
      icon,
      messageOne,
      messageTwo,
      pageTitle,
      onRetry,
    } = this.state;

    const onRetryHandler = onRetry || this.handleClickContinue;

    return (
      <div className={classes.page}>
        <AppBar
          noLeftNode={true}
          headerNode={pageTitle}
          rightNode={(
            <IconButton onClick={this.handleClickMenuIcon}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          {icon ? (
            <div className={classes.iconWrapper}>
              {icon}
            </div>
          ) : (
            <div className={classes.exclamationWrapper}>
              <CircleExclamationSolid />
            </div>
          )}
          <p><strong>{header}</strong></p>
          <p>{messageOne}</p>
          <p>{messageTwo}</p>
        </div>
        {!hideRetry ? (
          <div className={classes.continueButtonWrapper}>
            <Continue
              text="Retry"
              onClick={onRetryHandler}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

Timeout.propTypes = {
  classes: PropTypes.object.isRequired,
  errorScreens: PropTypes.object.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  logoutWithoutRedirect: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const {
    errorScreens,
    user: { loggedIn },
  } = state;

  return { errorScreens, loggedIn };
};

export default connect(mapStateToProps, { clearPros, logoutWithoutRedirect })(withStyles(styles)(Timeout));
