import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';

const styles = {
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pageContent: {
    marginTop: 20,
  },
  pageWrapper: {
    background: '#f5f5f5',
    minHeight: '100vh',
  },
  videoWrapper: {
    background: 'white',
    marginTop: 20,
    padding: 20,
  },
};

class CoreVitalHowToVideo extends React.Component {
  render() {
    const { classes, router } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
        />
        <div className={classes.pageContent}>
          <div className={classes.header}>
            Video (How to Use the Core Vitals)
          </div>
          <div className={classes.videoWrapper}>
            {'<< video goes here >>'}
          </div>
        </div>
      </div>
    );
  }
}

CoreVitalHowToVideo.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalHowToVideo);
