export class NoEulaError extends Error {
  constructor(message = 'No Eula') {
    super(message);
    this.name = 'NoEulaError';

    // a workaround to make `instanceof` work in ES5
    this.constructor = NoEulaError;
    Object.setPrototypeOf(this, NoEulaError.prototype);
  }
}

export class NotPatientLoginError extends Error {
  constructor(message = 'You must create a separate patient account with a new email to use this application.') {
    super(message);
    this.name = 'NotPatientLoginError';

    // a workaround to make `instanceof` work in ES5
    this.constructor = NotPatientLoginError;
    Object.setPrototypeOf(this, NotPatientLoginError.prototype);
  }
}
