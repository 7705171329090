import { throttle } from 'lodash';
import { browserHistory } from 'react-router';

import { logout } from '../state/user';
import store from '../store';

const logoutTime = 300000; // 5 minutes
const throttleTime = 5000; // 5 seconds
let timer;

function logoutIfNecessary() {
  const { loggedIn } = store.getState().user;

  if (loggedIn) {
    store.dispatch(logout(true));
    return;
  }

  browserHistory.push({
    pathname: '/timeout',
    state: {
      header: 'Error',
      messageOne: 'Your session has timed out due to inactivity.',
      hideRetry: true,
    },
  });
}

function resetTimer() {
  clearTimeout(timer);
  timer = setTimeout(logoutIfNecessary, logoutTime);
}

export const throttledReset = throttle(resetTimer, throttleTime, {
  leading: true,
  trailing: true,
});

export default function activityInitializer() {
  window.onload = throttledReset;
  document.onmousemove = throttledReset;
  document.onmousedown = throttledReset;
  document.ontouchstart = throttledReset;
  document.onclick = throttledReset;
  document.onscroll = throttledReset;
  document.onkeypress = throttledReset;
}
