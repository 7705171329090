import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import { browserHistory } from 'react-router';

import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import { CircleExclamationSolid } from '../lib/icons';
import Continue from './continue';
import AppBar from './app-bar';

const styles = {
  continueButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  exclamationWrapper: {
    marginTop: 25,
    marginBottom: 5,
    width: '8rem',
    height: '8rem',
    display: 'inline-block',
    marginRight: 10,
    '& path': {
      fill: colors.errorRed,
    },
  },
  paddingHorizontal10: {
    paddingLeft: 10,
    paddingRight: 10,
  },
  page: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: desktopMaxWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    minHeight: '100%',
    textAlign: 'center',
  },
  pageContent: {
    flexGrow: 1,
    fontSize: fontSizing.body,
  },
};

class HttpErrorScreen extends React.Component {
  render() {
    const {
      classes,
      header,
      messageOne,
      messageTwo,
      onClickRetry,
      vitalCorePageTitle,
    } = this.props;

    return (
      <div className={classes.page}>
        <div className={classes.pageContent}>
          {vitalCorePageTitle ? (
            <AppBar
              noLeftNode={true}
              headerNode={vitalCorePageTitle}
              rightNode={(
                <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              )}
            />
          ) : null}
          <div className={classes.paddingHorizontal10}>
            <div className={classes.exclamationWrapper}>
              <CircleExclamationSolid />
            </div>
            <div><strong>{header}</strong></div>
            <p>{messageOne}</p>
            <p>{messageTwo}</p>
          </div>
        </div>
        <div className={classes.continueButtonWrapper}>
          <Continue
            text="Retry"
            onClick={onClickRetry}
          />
        </div>
      </div>
    );
  }
}

HttpErrorScreen.defaultProps = {
  vitalCorePageTitle: '',
};

HttpErrorScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  header: PropTypes.string.isRequired,
  messageOne: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  messageTwo: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  onClickRetry: PropTypes.func.isRequired,
  vitalCorePageTitle: PropTypes.string,
};

export default withStyles(styles)(HttpErrorScreen);
