const sdpConstraints = {
  optional: [],
  mandatory: {
  },
};

const vitalCoreConfig = {
  termsAndConditionsVersion: '1',
  consentToRecordVersion: '1',
  ivcVersion: '1.0.0',
  ivcWebAppVersion: process.env.REACT_APP_IVC_WEB_APP_VERSION,
};

const env = {
  API_URL: process.env.REACT_APP_IVC_URL || `http://${(new URL(document.location.href)).hostname}:3002`,
  PATIENT_APP_URL: process.env.REACT_APP_PATIENT_APP || `http://${(new URL(document.location.href)).hostname}:3010`,
  rtc: {
    cfg: { 'iceServers': [{ 'urls': 'stun:stun.l.google.com:19302' }] },
    options: { 'optional': [{ 'DtlsSrtpKeyAgreement': true }] }
  },
  sdpConstraints,
  vitalCoreConfig,
};

export default env;
