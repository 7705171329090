import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Layout extends Component {
  render() {
    return (
      <div className="layout" style={this.props.style}>
        {this.props.children}
      </div>
    );
  }
}

Layout.defaultProps = {
  style: {},
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};

export default Layout;
