import { muiGlobalMedNextTheme } from '../themes/globalmed/theme';
import { muiMindsetNextTheme } from '../themes/mindset/theme';
import * as globalmedStyles from '../themes/globalmed/styles';
import * as mindsetStyles from '../themes/mindset/styles';

const url = new URL(document.location.href);
let brand;

if (url.host.includes('globalmed')) {
  brand = {
    name: 'globalmed',
    source_id: 4,
    nextTheme: muiGlobalMedNextTheme,
    title: 'Encounter',
    styles: { ...globalmedStyles },
    loginLogo: 'globalmed_logo.png'
  }
} else {
  brand = {
    name: 'mindset',
    source_id: 1,
    nextTheme: muiMindsetNextTheme,
    title: 'Informed',
    styles: { ...mindsetStyles },
    loginLogo: 'informed.svg',
  }
}

export default brand;
