import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { IconButton, LinearProgress } from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';

import AppBar from '../components/app-bar';
import Page from './page';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import QuestionLayout from '../layouts/question';

const classes = {
  ecgImage: {
    marginBottom: 20,
    marginTop: 20,
    maxWidth: '100%',
  },
  nextButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    marginBottom: 20,
    padding: 10,
    width: '100%',
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: 'white',
      },
    },
    '&:hover': {
      background: colors.primaryColor,
      color: 'white',
    },
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.questionBackground,
  },
  pageContent: {
    boxSizing: 'border-box',
    fontSize: fontSizing.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 10,
    paddingRight: 10,
    width: '100%',
    backgroundColor: colors.questionBackground,
  },
  pageContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontSize: fontSizing.body,
    overflowX: 'scroll',
    backgroundColor: colors.questionBackground,
  },
};

class IvcPrClinicalStudy2 extends Page {
  render() {
    const { classes } = this.props;

    return (
      <QuestionLayout
        primaryBtnLabel="Next"
        primaryBtnOnTouchTap={() => { this.forwardWithQuery(this.props.location.query); }}
        style={{ backgroundColor: colors.questionBackground }}
      >
        <div className={classes.pageContentContainer}>
          <AppBar
            headerNode="ECG Instructions"
            backButtonOnClick={() => this.props.router.goBack()}
            rightNode={(
              <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
                <MenuIcon fontSize="large" />
              </IconButton>
            )}
          />
          <LinearProgress
            variant="determinate"
            value={(this.getCurrentPage() / this.getTotalPages()) * 100}
            classes={{ bar: classes.linearProgress }}
          />
          <div className={classes.pageContent}>
            <ol>
              <li>
                <div>Make sure ECG electrodes are properly positioned and securely attached to the subject.</div>
                <img
                  src="/img/ECG.png"
                  alt="depiction of electrode placement"
                  className={classes.ecgImage}
                />
              </li>
              <li>When you're ready, tap the <strong>START</strong> button on the next screen and the <strong>PRINT/STOP</strong> button on the ECG simultaneously.</li>
            </ol>
          </div>
        </div>
      </QuestionLayout>
    );
  }
}

IvcPrClinicalStudy2.propTypes = {
  classes: PropTypes.object.isRequired,
  locations: PropTypes.object.isRequired,
};

export default withStyles(classes)(IvcPrClinicalStudy2);
