import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import AppBar from '../components/app-bar';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';
import IvcVersionInfo from '../components/ivc-version-info';

const styles = {
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    marginBottom: 20,
    textAlign: 'center',
  },
  pageContent: {
    fontSize: fontSizing.body,
    marginTop: 20,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingLeft: 20,
    paddingRight: 20,
  },
  pageWrapper: {
    background: colors.white,
    minHeight: '100vh',
    paddingBottom: 20,
  },
  sectionHeader: {
    fontWeight: 'bold',
    marginTop: 20,
  },
};

class CoreVitalUserReleaseNotes extends React.Component {
  render() {
    const { classes, router } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          <div className={classes.header}>
            Release Notes
          </div>
          <IvcVersionInfo />
        </div>
      </div>
    );
  }
}

CoreVitalUserReleaseNotes.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalUserReleaseNotes);
