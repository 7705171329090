import React from 'react';

import { throttledReset } from '../initializers/activity';

class DisableSessionTimeout extends React.Component {
  constructor(props) {
    super(props);
    this.activityInterval = setInterval(throttledReset, 10000);
  }

  componentWillUnmount = () => {
    clearInterval(this.activityInterval);
  }

  render() {
    return null;
  }
}

export default DisableSessionTimeout;
