import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import MobileDetect from 'mobile-detect';
import {
  Button,
  CircularProgress,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { withStyles } from '@material-ui/core/styles';
import NoSleep from 'nosleep.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { pick } from 'lodash';

import Page from './page';
import HiddenContent from '../components/hidden-content';
import { updatePRO } from '../state/pro-forms';
import { startVideo } from '../lib/createAnswer';
import { colors, fontSizing } from '../lib/styles';
import startVitalsRunner, {
  addCallbacksToWebWorker,
  createVitalsRunnerWebWorker,
  isWebWorkerReady,
  killWebWorker,
  loadDetector,
  preparationVideo,
  resetVitalsRunnerOnUnmount,
  setGuideBoxColor,
  setVitalsRunnerStateForReload,
  toggleDevMode,
} from '../lib/vitals-runner';
import { throttledReset } from '../initializers/activity';
import { logout } from '../state/user';
import { ClockWithError, NoInternet } from '../lib/icons';
import { setErrorScreenData } from '../state/error-screens';
import { vcDataStatusMessageMap, vcProcessDataStatus } from '../lib/vital-core';

const processingTimeoutLength = 300000;

const styles = {
  exclamationWrapper: {
    marginTop: '10rem',
    marginBottom: '8rem',
    '& path': {
      fill: colors.errorRed,
    },
    textAlign: 'center',
  },
  exclamationWrapperSmall: {
    display: 'inline-block',
    marginRight: 10,
    width: 25,
    '& path': {
      fill: 'white',
    },
  },
  fab: {
    margin: '0px',
    top: '20px',
    right: '20px',
    bottom: 'auto',
    left: 'auto',
    position: 'fixed',
  },
  heartIcon: {
    color: colors.healthyRed,
    fontSize: '25px',
  },
  logo: {
    height: '200px',
    paddingTop: '100px',
    width: '200px',
  },
  divider: {
    margin: '30px 0px',
  },
  waitingView: {
    height: '450px',
    maxHeight: '75vh',
    maxWidth: '80%',
    margin: '0px auto 10rem',
    textAlign: 'center',
    width: '100%',
  },
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    display: 'none',
  },
  loadingModel: {
    fontSize: fontSizing.body,
    position: 'relative',
  },
  heart: {
    marginLeft: '5px',
    position: 'relative',
    top: '-34px',
  },
  vitals: {
    display: 'flex',
    padding: '0px 10px',
    justifyContent: 'center',
    marginBottom: 40,
  },
  breath: {
    position: 'relative',
    top: '-63px',
  },
  cameraErrorText: {
    color: colors.errorRed,
    fontSize: fontSizing.body,
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    position: 'absolute',
    top: 0,
  },
  container: {
    backgroundColor: colors.questionBackground,
    height: '100%',
    position: 'relative',
  },
  hidden: {
    display: 'none',
  },
  topSection: {
    backgroundColor: colors.questionBackground,
    height: '100%',
    position: 'relative',
  },
  progressBad: {
    color: 'red',
  },
  progressGood: {
    color: 'green',
  },
  progressNone: {
    color: 'grey',
  },
  patientCanvas: {
    display: 'none',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  patientCanvasVisible: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  videoContainer: {
    margin: '0px auto',
    height: '100%',
    maxWidth: '540px',
    width: '100%',
  },
  icon: {
    width: 25,
    height: 25,
    position: 'absolute',
  },
  iconWrapper: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0 2px 3px lightgrey',
    display: 'flex',
    height: 60,
    justifyContent: 'center',
    position: 'absolute',
    left: 0,
    top: 0,
    transform: 'translateX(20px)',
    width: 60,
    zIndex: 2,
  },
  iconBackground: {
    alignItems: 'center',
    background: '#fff',
    borderRadius: '50%',
    boxShadow: '0 0 4px lightgrey',
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    left: 5,
    position: 'absolute',
    top: 5,
    width: 50,
    zIndex: 2,
  },
  imReadyButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    maxWidth: 450,
    padding: 10,
    '&:disabled': {
      background: '#d9d8d8',
    },
  },
  imReadyButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
  },
  vitalDisplay: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: 80,
    paddingBottom: 5,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 35,
    width: 90,
  },
  vitalDisplayUnits: {
    fontSize: fontSizing.smallX,
    marginBottom: 5,
    marginTop: 5,
    textAlign: 'center',
  },
  vitalDisplayValue: {
    fontSize: fontSizing.body,
    fontWeight: 'bold',
  },
  vitalsBorderBlue: {
    borderColor: '#1e7bcd',
  },
  vitalsBorderRed: {
    borderColor: '#cd3636',
  },
  vitalsIconBlue: {
    color: '#1e7bcd',
  },
  vitalsIconRed: {
    color: '#cd3636',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  calibratingCameraMessage: {
    background: colors.primaryColor,
    color: '#fff',
    fontSize: fontSizing.body,
    marginTop: 40,
    padding: 10,
    textAlign: 'center',
  },
  dialogText: {
    fontSize: fontSizing.body,
  },
  bodyText: {
    fontSize: fontSizing.body,
  },
  vitalCoreErrorContainer: {
    background: colors.errorRed,
    color: 'white',
    fontSize: fontSizing.body,
    padding: 10,
  },
  vitalCoreErrorText: {
    textAlign: 'center',
  },
  vitalCoreErrorRow: {
    display: 'flex',
    justifyContent: 'center',
  },
  linearProgress: {
    background: 'lightgrey',
    height: 40,
  },
  linearProgressText: {
    left: 10,
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    zIndex: 2,
  },
  processingSection: {
    fontSize: fontSizing.body,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 60,
    textAlign: 'center',
  },
  circularProgressContainer: {
    alignItems: 'center',
    backgroundColor: colors.questionBackground,
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 40,
    position: 'relative',
  },
  circularProgressText: {
    alignItems: 'center',
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    left: 0,
    position: 'absolute',
    right: 0,
    top: 0,
  },
  circularProgressTextPercent: {
    fontSize: fontSizing.smallXX,
  },
  circularProgressWrapper: {
    display: 'inline-flex',
  },
  errorSection: {
    maxWidth: 600,
    padding: 10,
    '& a': {
      fontSize: fontSizing.body,
    },
  },
  retryButton: {
    backgroundColor: colors.primaryColor,
    borderRadius: '0.3125rem',
    minWidth: '17.1875rem',
    color: colors.white,
    marginBottom: '0.625rem',
    marginTop: 40,
    width: '100%',
    minHeight: '4rem',
    fontSize: fontSizing.body,
    '@media (hover: none)': {
      '&:hover': {
        background: colors.primaryColor,
        color: colors.white,
      },
    },
  },
  menuButtonOuterWrapper: {
    display: 'flex',
    justifyContent: 'end',
  },
  menuButtonWrapper: {
    background: 'rgba(211,211,211,0.25)',
    borderBottomLeftRadius: 5,
    display: 'flex',
    justifyContent: 'end',
    padding: 10,
  },
  header: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    zIndex: 1,
  },
};

class VitalCoreBenchHr8 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      reader: null,
      disconnected: false,
      deviceReadings: [],

      cameraState: '',
      cameraError: '',
      me: {},
      signalPercent: 0,
      connecting: false,
      workerPct: 0,
      workerReady: false,
      md: null,
      HR: null,
      errorDialogOpen: false,
      timeLeft: 0,
      percentLeft: 0,
      processing: false,
      cachedFrameCount: 0,
      processedFrameCount: 0,
      vr: null,
      latestTouchTap: {
        time: 0,
        target: null,
      },
      startedVitalsMeasurements: false,
      patientVideoDataLoaded: false,
      showImReady: true,
      coreWarnings: [],
      signsMsgs: [],
      patientCanvasVisibleHeight: null,
      patientCanvasVisibleWidth: null,
    };

    this.patientCanvas = React.createRef();
    this.patientCanvasVisible = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();

    this.patientCanvasVisibleRAF = null;

    this.moduleDownloadingTimer = null;
    this.processingTimer = null;
    this.noSleep = null;

    addCallbacksToWebWorker({
      vrOnsigns: this.vrOnsigns,
      vrOnprocessing: this.vrOnprocessing,
      vrOnend: this.vrOnend,
    });

    this.reloadingVitalsRunner = false;
  }

  componentDidMount = () => {
    const { vitalCoreBenchHr } = this.props;
    const { reader } = this.getDataMap(vitalCoreBenchHr);
    const md = new MobileDetect(window.navigator.userAgent);

    let disconnected = false;
    if (reader) {
      disconnected = !reader.connected()
    }

    this.setState({ md, reader, disconnected });

    this.startPreparationVideo();
  }

  componentWillUnmount = () => {
    this.endCall();
    window.cancelAnimationFrame(this.patientCanvasVisibleRAF);
    if (this.noSleep) this.noSleep.disable();
    const videoElement = this.patientVideo.current;
    if (videoElement && videoElement.srcObject) {
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    }
    if (this.state.vr) {
      this.state.vr.onend = null;
    }
    if (!this.reloadingVitalsRunner) {
      // only reset vitals runner state when core is not getting reloaded.
      // reloading core
      resetVitalsRunnerOnUnmount();
    }
  }

  setDevMode = (e) => {
    // mobile workaround for double clicks
    const touchTap = {
      time: Date.now(),
      target: e.currentTarget,
    };
    const { latestTouchTap: ltt } = this.state;
    if (ltt && (ltt.target === touchTap.target) && (touchTap.time - ltt.time < 300)) {
      toggleDevMode();
    }
    this.setState({ latestTouchTap: touchTap });
  };

  endCall = () => {
    try {
      clearInterval(this.heartbeatInterval);
      clearInterval(this.activityTimer);
      clearTimeout(this.processingTimer);
    } catch (e) {
      console.error('error ending call properly: ', e);
    }
  }

  handleCloseErrorDialog = () => {
    this.setState({ errorDialogOpen: false });
  };

  handleRetry = () => {
    this.setState({
      cameraError: '',
      errorDialogOpen: false,
      showImReady: true,
    });

    this.startPreparationVideo();
  };

  startPreparationVideo = async () => {
    const { me } = this.state;

    try {
      const stream = await startVideo(me, {
        audio: false,
        video: {
          facingMode: 'user',
        },
      });
      const patientVideo = this.patientVideo.current;

      patientVideo.addEventListener('loadeddata', async () => {
        loadDetector();
        const patientCanvasVisible = this.patientCanvasVisible.current;
        preparationVideo(patientVideo, patientCanvasVisible);
        this.setState({ patientVideoDataLoaded: true });
      });

      patientVideo.srcObject = me.stream;
      try {
        if (this.state.md.os() === 'iOS' && this.state.md.mobile()) {
          console.info('not manually playing video because', this.state.md.os(), this.state.md.mobile());
        } else {
          await patientVideo.play();
        }
      } catch (err) {
        console.error('error starting video', err);
      }

      this.setState({
        patientCanvasVisibleHeight: patientVideo.videoHeight,
        patientCanvasVisibleWidth: patientVideo.videoWidth,
        stream,
      });
    } catch (err) {
      this.setState({ cameraError: err, errorDialogOpen: true });
    }
  };

  addWarning = (warningCode) => {
    const { coreWarnings } = this.state;

    const warningCodeMessage = vcDataStatusMessageMap[warningCode];

    if (coreWarnings.findIndex(warning => warning.code === warningCode) === -1) {
      this.setState((prevState) => {
        const coreWarningObj = {
          code: warningCode,
          message: warningCodeMessage,
        };
        return { coreWarnings: [...prevState.coreWarnings, coreWarningObj] };
      });
      setGuideBoxColor('red');

      setTimeout(() => {
        this.setState((prevState) => {
          const filteredWarnings = prevState.coreWarnings.filter(curWarning => curWarning.code !== warningCode);

          if (!filteredWarnings.length) {
            setGuideBoxColor('green');
          }

          return { coreWarnings: filteredWarnings };
        });
      }, 3000);
    }
  };

  vrOndownloadingPct = (data) => {
    this.setState({ workerPct: data.pct });
  };

  vrOntimeLeft = (timeLeft, percentLeft) => {
    this.setState({ timeLeft, percentLeft });
  };

  vrOnprocessing = async (processing) => {
    const { reader } = this.state;

    if (processing) {
      // stop streaming data when processing starts
      if (reader) {
        await reader.stopStreaming();
      }

      this.processingTimer = setTimeout(() => {
        // if we timeout we exit pr early so disconnect
        if (reader) {
          reader.disconnect();
        }

        window.localStorage.removeItem('vitalCoreVisitedTerms');
        window.localStorage.removeItem('vitalCoreVisitedConsentToRecord');
        window.localStorage.removeItem('vitalCoreVisitedInstructions');
        
        const handleRetry = () => {
          const { pathname, search } = this.props.location;
          this.props.router.push(`${pathname}${search}`);
        };

        this.props.setErrorScreenData({
          header: 'Timed Out',
          messageOne: 'Your session has timed out and we were unable to analyze your vitals',
          messageTwo: <>Please tap <strong>RETRY.</strong></>,
          notificationAuthLogoutRoute: this.props.notificationAuthLogoutRoute,
          pageTitle: 'Analyzing Results',
          icon: <ClockWithError />,
          onRetry: handleRetry,
          noLogout: true,
        });

        this.props.router.push('/timeout');
      }, processingTimeoutLength);
    }

    const videoElement = this.patientVideo.current;
    if (videoElement && videoElement.srcObject) {
      videoElement.srcObject.getTracks().forEach(t => t.stop());
      videoElement.srcObject = null;
    }

    this.setState({ processing });
  };

  vrOnsigns = (data) => {
    const { vitalCoreBenchHr } = this.props;
    const { HR: curHR, signsMsgs } = this.state;
    const { signs, cachedFrameCount, processedFrameCount } = data;
    signsMsgs.push(pick(signs, ['timestamp', 'HR', 'snrHR', 'dataStatus']));
    const {
      signalPercent,
      hrSignalPercent,
      dataStatus,
    } = signs;
    let { HR } = signs;

    if (curHR > 0 && HR < 1) HR = curHR;

    const processedDataStatus = vcProcessDataStatus(dataStatus);

    if (processedDataStatus.action === 'warning') {
      // console.log('warning');
      // console.log({ processedDataStatus });
      processedDataStatus.data.forEach((dataStatusMessage) => { this.addWarning(dataStatusMessage); });
    } else if (processedDataStatus.action === 'restart' || processedDataStatus.action === 'reload') {
      let {
        criticalErrorCount,
        sessionCount,
        vitalsMeasurements: prevVitalsMeasurements,
      } = this.getDataMap(this.props.vitalCoreBenchHr);

      prevVitalsMeasurements = prevVitalsMeasurements || [];
      criticalErrorCount = criticalErrorCount || 0;
      sessionCount = sessionCount || 0;

      this.props.updatePRO({
        type: 'vitalCoreBenchHr',
        position: this.getTrackIndex(),
        value: {
          ...vitalCoreBenchHr[this.getTrackIndex()],
          vitalsMeasurements: [
            signs,
            ...prevVitalsMeasurements,
          ],
          criticalErrorCount: criticalErrorCount + 1,
          sessionCount: sessionCount + 1,
          shouldRestart: true,
          errorCode: processedDataStatus.data,
          signsMsgs,
        },
      });

      this.setState({ signsMsgs: [] });

      if (processedDataStatus.action === 'reload') {
        this.reloadingVitalsRunner = true;
        killWebWorker();
        setVitalsRunnerStateForReload();
        createVitalsRunnerWebWorker();
      }

      this.forwardWithQuery(this.props.location.query);
    }

    this.setState({
      HR,
      signalPercent,
      hrSignalPercent,
      cachedFrameCount,
      processedFrameCount,
    });
  };

  vrOnend = (lastSigns) => {
    let {
      criticalErrorCount,
      sessionCount,
      vitalsMeasurements: prevVitalsMeasurements,
    } = this.getDataMap(this.props.vitalCoreBenchHr);

    // if we disconnected we're restarting so don't move on
    if (this.state.disconnected) {
      return;
    }

    prevVitalsMeasurements = prevVitalsMeasurements || [];
    criticalErrorCount = criticalErrorCount || 0;
    sessionCount = sessionCount || 0;

    let readings = [];
    let sessionTimestamps;
    if (lastSigns && lastSigns.sessionTimestampsHRStr && typeof lastSigns.sessionTimestampsHRStr === 'string') {
      sessionTimestamps = lastSigns.sessionTimestampsHRStr.split(',').map(t => Number(t));
    }

    if (sessionTimestamps && sessionTimestamps.length > 0) {
      // filter out deviceReadings which are not within 1s of a frame
      readings = this.state.deviceReadings.filter((reading) => {
        let inRange = false;
        sessionTimestamps.every((timestamp) => {
          if (Math.abs(reading.timestamp - timestamp) <= 1000) {
            inRange = true;
            return false; // break
          }
          return true; // continue
        });
        return inRange;
      });
    } else {
      readings = this.state.deviceReadings;
    }

    const sum = readings.reduce((result, item) => {
      return result + item.HR;
    }, 0);
    const deviceHRAverage = Math.round(sum / readings.length);

    const value = {
      vitalsMeasurements: [
        lastSigns,
        ...prevVitalsMeasurements,
      ],
      sessionCount: sessionCount + 1,
      signsMsgs: this.state.signsMsgs,
      deviceHRAverage,
      deviceReadings: this.state.deviceReadings,
    };

    const processedDataStatus = vcProcessDataStatus(lastSigns.dataStatus, true);
    if (processedDataStatus.action === 'restart') {
      value.shouldRestart = true;
      value.criticalErrorCount = criticalErrorCount + 1;
      value.errorCode = processedDataStatus.data;
    }

    this.props.updatePRO({
      type: 'vitalCoreBenchHr',
      position: this.getTrackIndex(),
      value,
    });
    this.forwardWithQuery(this.props.location.query);
  };

  startCamera = async () => {
    try {
      this.activityTimer = setInterval(throttledReset, 10000);
      this.setState({
        cameraState: 'started',
        showImReady: false,
        startedVitalsMeasurements: true,
      });
      const patientVideo = this.patientVideo.current;
      const canvas = this.patientCanvas.current;
      const patientCanvasVisible = this.patientCanvasVisible.current;

      this.moduleDownloadingTimer = setTimeout(() => {
        window.localStorage.removeItem('vitalCoreVisitedTerms');
        window.localStorage.removeItem('vitalCoreVisitedConsentToRecord');
        window.localStorage.removeItem('vitalCoreVisitedInstructions');

        if (!navigator.onLine) {
          this.props.setErrorScreenData({
            header: 'Lost Internet Connection',
            messageOne: <i>Unable to download the IVC App.</i>,
            messageTwo: <>Please try again later when you have access to the Internet and tap <strong>RETRY.</strong></>,
            notificationAuthLogoutRoute: this.props.notificationAuthLogoutRoute,
            pageTitle: 'No Internet',
            icon: <NoInternet />,
          });
          this.props.router.push('/timeout');
          return;
        }

        this.props.updatePRO({
          type: 'vitalCoreBenchHr',
          position: this.getTrackIndex(),
          value: {
            vitalsMeasurements: [
              { HR: -1 },
            ],
            deviceError: true,
          },
        });

        this.forwardWithQuery(this.props.location.query);
      }, processingTimeoutLength);

      this.noSleep = new NoSleep();
      this.noSleep.enable();

      const startWhenWorkerIsReady = async () => {
        const { reader } = this.state;
        const workerIsReady = isWebWorkerReady();
        if (!workerIsReady) {
          return window.requestAnimationFrame(startWhenWorkerIsReady);
        } else {

          if (reader) {

            // start streaming when start buton pressed and webworker starts
            // todo catch not connected here and restart pro?
            await reader.stream(async (deviceData, deviceError) => {
              if (deviceError) {
                if (deviceError.code && deviceError.code === 901) {
                  return this.setState({ deviceError, deviceData, disconnected: true });
                }

                // todo currently dont do anything with non disconnect errors...
                return this.setState({ deviceError });
              }

              const { deviceReadings } = this.state;
              deviceReadings.push({ ...deviceData, timestamp: Date.now() });
              this.setState({ deviceReadings });
            });
          }

          this.setState({ workerReady: true });
          clearTimeout(this.moduleDownloadingTimer);
          startVitalsRunner(patientVideo, canvas, patientCanvasVisible, {
            vrOnprocessing: this.vrOnprocessing,
            vrOntimeLeft: this.vrOntimeLeft,
            vrOnend: this.vrOnend,
            vrGetCoreWarnings: this.vrGetCoreWarnings,
          });

        }
      };

      return window.requestAnimationFrame(startWhenWorkerIsReady);
    } catch (err) {
      this.setState({ cameraError: err, errorDialogOpen: true });
    }
  }

  vrGetCoreWarnings = () => {
    return this.state.coreWarnings;
  }

  render() {
    const { classes, router, location } = this.props;
    const {
      errorDialogOpen,
      startedVitalsMeasurements,
      patientVideoDataLoaded,
      processing,
      showImReady,
      coreWarnings,
      patientCanvasVisibleHeight,
      patientCanvasVisibleWidth,
    } = this.state;
    const videoContainerStyle = { display: this.state.cameraDenied ? 'none' : '' };

    return (
      <div className={classes.container}>
        {!processing ? (
          <div className={classes.header}>
            {coreWarnings.length ? (
              <div className={classes.vitalCoreErrorContainer}>
                {coreWarnings.map(vitalCoreError => (
                  <div className={classes.vitalCoreErrorRow} key={vitalCoreError.code}>
                    <div className={classes.exclamationWrapperSmall}>
                      <FontAwesomeIcon icon={faExclamationTriangle} />
                    </div>
                    <div className={classes.vitalCoreErrorText}>{vitalCoreError.message}</div>
                  </div>
                ))}
              </div>
            ) : null}
            <div className={classes.menuButtonOuterWrapper}>
              <div className={classes.menuButtonWrapper}>
                <IconButton onClick={() => router.push('/core-vital-user-manual-menu')}>
                  <MenuIcon fontSize="large" />
                </IconButton>
              </div>
            </div>
          </div>
        ) : null}
        {processing && !errorDialogOpen ? (
          <div className={classes.processingSection}>
            <div className={classes.circularProgressContainer}>
              <div className={classes.circularProgressWrapper}>
                <CircularProgress
                  value={Math.round((this.state.processedFrameCount / this.state.cachedFrameCount) * 100)}
                  variant="determinate"
                  size={100}
                />
              </div>
              <div className={classes.circularProgressText}>
                <div>
                  <span>{Math.round((this.state.processedFrameCount / this.state.cachedFrameCount) * 100)}</span>
                  <span className={classes.circularProgressTextPercent}>%</span>
                </div>
              </div>
            </div>
            <p><strong>Analyzing...</strong></p>
            <p>Please sit tight while we analyze your video.</p>
            <p>This should only take a minute or two.</p>
          </div>
        ) : null}
        {!processing && !errorDialogOpen ? (
          <section className={classes.topSection}>
            <Snackbar
              open={this.state.disconnected}
              autoHideDuration={5000}
              onClose={() => this.goToIndexWithQuery(0, location.query)}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>The device has disconnected. Please restart</MuiAlert>
            </Snackbar>

            <div
              id="videoContainer"
              className={classes.videoContainer}
              style={videoContainerStyle}
              ref={this.videoContainer}
            >
              <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
              <canvas id="patientCanvas" ref={this.patientCanvas} style={styles.patientCanvas} />
              <canvas
                id="patientCanvasVisible"
                ref={this.patientCanvasVisible}
                style={styles.patientCanvasVisible}
                height={patientCanvasVisibleHeight}
                width={patientCanvasVisibleWidth}
              />
            </div>
            <div className={classes.nonVideoContentContainer}>
              <HiddenContent hidden={!showImReady}>
                <div className={classes.imReadyButtonWrapper}>
                  <button
                    className={classes.imReadyButton}
                    onClick={this.startCamera}
                    type="button"
                    disabled={!patientVideoDataLoaded}
                  >
                    START
                  </button>
                </div>
              </HiddenContent>
              <HiddenContent hidden={this.state.workerReady || !startedVitalsMeasurements}>
                <div className={classes.loadingModel}>
                  <span className={classes.linearProgressText}>Loading</span>
                  <LinearProgress
                    className={classes.linearProgress}
                    value={Math.round(this.state.workerPct * 100)}
                    variant="indeterminate"
                  />
                </div>
              </HiddenContent>
              <HiddenContent hidden={(!this.state.workerReady || (this.state.timeLeft <= 1)) || !startedVitalsMeasurements}>
                <div
                  className={classes.loadingModel}
                  onClick={this.setDevMode}
                >
                  <span className={classes.linearProgressText}>Collecting</span>
                  <LinearProgress
                    className={classes.linearProgress}
                    value={Math.round((1 - this.state.percentLeft) * 100)}
                    variant="determinate"
                  />
                </div>
              </HiddenContent>
            </div>
          </section>
        ) : null}
        {errorDialogOpen ? (
          <div className={classes.errorSection}>
            <div className={classes.exclamationWrapper}>
              <FontAwesomeIcon icon={faExclamationTriangle} size="8x" />
            </div>
            <div className={classes.dialogText}>
              Unable to take your vitals. This web-app needs permission to use your camera to measure your vitals.
            </div>
            <br />
            <div className={classes.dialogText}>
              If you decide to take your vitals, enable camera permissions in your browser, and please return to your
              message app and press the link in the text message.
            </div>
            <p>
              <Link to="/core-vital-turn-on-permissions">
                View instructions to turn on permissions
              </Link>
            </p>
            <p>
              <Button
                onClick={this.handleRetry}
                className={classes.retryButton}
              >
                Retry
              </Button>
            </p>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    proForms: { vitalCoreBenchHr },
    user: { notificationAuthLogoutRoute },
  } = state;

  return { notificationAuthLogoutRoute, vitalCoreBenchHr };
};

export default connect(mapStateToProps, { logout, setErrorScreenData, updatePRO })(withStyles(styles)(VitalCoreBenchHr8));
