/* eslint-disable no-bitwise */
import { fromHexString, toLE16, crc8 } from './bits';

export class HRError extends Error {
  constructor(message) {
    super(message);
    this.name = 'HRError';
  }
}

// Received Data
// 77 11 050000400014ff14ff14ff3cffff14ff 29
//  |  |                                |   `---- crc8 checksum (drop from crc)
//  |  |                                 `------- packet
//  |   `---------------------------------------- length (17 dec) (drop from crc)
//   `------------------------------------------- start frame (drop from crc)

// Internal Packet
// 05 00004000 14 ff 14 ff 14 ff 3c ffff 14 ff
//  |        |  |  |  |  |  |  |  |    |  |   `---- Respitory Rate
//  |        |  |  |  |  |  |  |  |    |   `------- Respitory Rate Exception
//  |        |  |  |  |  |  |  |  |     `---------- Perfusion Index
//  |        |  |  |  |  |  |  |  |
//  |        |  |  |  |  |  |  |   `--------------- Perfusion Index Exception
//  |        |  |  |  |  |  |   `------------------ Pleth Variability Index
//  |        |  |  |  |  |   `--------------------- Pleth Variability Exception
//  |        |  |  |  |   `------------------------ Pulse Rate
//  |        |  |  |   `--------------------------- Pulse Rate Exception
//  |        |  |   `------------------------------ SpO2
//  |        |   `--------------------------------- SpO2 Exception
//  |         `------------------------------------ ?
//   `--------------------------------------------- packet type (05 is sensors)

function bpEvent(evt, intermediateFn) {
  const notificationVal = new Uint8Array(evt.target.value.buffer);

  const header = notificationVal[0];
  const len = notificationVal[1];
  const packet = notificationVal.slice(2, -1);
  const crc = notificationVal[notificationVal.length - 1];
  const dataType = packet[0];

  if (header === 0x77 && dataType === 0x05 && intermediateFn) {
    if (len !== notificationVal.length - 2) {
      intermediateFn({ packet: notificationVal }, new HRError('packet wrong length'));
    } else if (crc8(packet) !== crc) {
      intermediateFn({ packet: notificationVal }, new HRError('bad crc'));
    } else {
      const spO2Exc = packet[5];
      const SPO2 = packet[6];
      const pulseRateExc = packet[7];
      const HR = packet[8];
      const plethVariabilityIndexExc = packet[9];
      const plethVariabilityIndex = packet[10];
      const perfusionIndexExc = packet[11];
      const perfusionIndex = toLE16(packet.slice(12, 14)) / 100;
      const respRateExc = packet[14];
      const BR = packet[15];

      intermediateFn({ SPO2, HR, BR, packet: notificationVal, spO2Exc, pulseRateExc, plethVariabilityIndexExc, plethVariabilityIndex, perfusionIndexExc, perfusionIndex, respRateExc });
    }
  }
}

export class HrReader {
  device;

  server;

  service;

  readChar;

  writeChar;

  intermediateFn;

  // throws bluetooth errors
  constructor() {
    return (async () => {
      // throws if couldnt get bluetooth peripheral? perhaps no ble present or permissions problem
      this.device = await navigator.bluetooth.requestDevice({
        filters: [{
          name: ['MightySat'],
        }],
        optionalServices: ['54c21000-a720-4b4f-11e4-9fe20002a5d5'],
      });

      this.device.addEventListener('gattserverdisconnected', this._disconnected.bind(this));

      // throws if couldnt connect, perhaps low battery or device too far
      this.server = await this.device.gatt.connect();

      // these throw if couldnt talk to device during initial setup
      // todo wrap them up to a single error?
      this.service = await this.server.getPrimaryService('54c21000-a720-4b4f-11e4-9fe20002a5d5');
      this.writeChar = await this.service.getCharacteristic('54c21001-a720-4b4f-11e4-9fe20002a5d5');
      this.readChar = await this.service.getCharacteristic('54c21002-a720-4b4f-11e4-9fe20002a5d5');

      return this;
    })();
  }

  async stream(intermediateFn) {
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      if (typeof this.intermediateFn !== 'undefined') {
        await this.stopStreaming();
      }

      this.intermediateFn = intermediateFn;
      this.readChar.addEventListener('characteristicvaluechanged', (evt) => { bpEvent(evt, this.intermediateFn); });

      return this.readChar.startNotifications()
        .then(() => {
          return this.writeChar.writeValueWithoutResponse(fromHexString('7705031f0003d6'));
        });
    }
    throw new HRError('Not connected');
  }

  async stopStreaming() {
    if (this.readChar && this.device && this.device.gatt && this.device.gatt.connected) {
      this.readChar.removeEventListener('characteristicvaluechanged', (evt) => { bpEvent(evt, this.intermediateFn); });
      await this.readChar.stopNotifications();
    }
    this.intermediateFn = undefined;
  }

  async _disconnected() {
    if (this.intermediateFn) {
      const error = new HRError('Device Disconnected');
      error.code = 901;
      this.intermediateFn(null, error);
    }

    await this.stopStreaming();

    if (this.device && this.device.gatt && this.device.gatt.connected) {
      this.device.removeEventListener('gattserverdisconnected', this.disconnect.bind(this));
    }
  }

  disconnect() {
    if (this.device && this.device.gatt && this.device.gatt.connected) {
      this.device.gatt.disconnect();
    }
  }

  connected() {
    return this.device && this.device.gatt && this.device.gatt.connected;
  }
}
