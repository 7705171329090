import queryString from 'query-string';
import { omitBy, startsWith, isUndefined } from 'lodash';

import config from '../config';

export function makeShadowOptions(body, token, method = 'PUT') {
  const reqOptions = { method };
  if (method === 'GET') {
    reqOptions.query = body;
  } else {
    reqOptions.body = body;
  }
  if (token) {
    reqOptions.credentials = 'omit';
    reqOptions.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  return reqOptions;
}

export default function handledFetch(path, options) {
  return fetch(path, options)
    .then((res) => {
      if (res.status >= 400) {
        const err = new Error('Bad response from server');
        err.status = res.status;
        const contentType = res.headers.get('content-type');

        if (startsWith(contentType, 'application/json')) {
          return res.json()
            .then((content) => {
              err.content = content;
              throw err;
            });
        }

        return res.text()
          .then((content) => {
            err.content = content;
            throw err;
          });
      }
      return res;
    });
}

export function apiFetch(path, options = {}) {
  let qs = '';
  const isFormData = options.body instanceof FormData;
  if (typeof options.body === 'object' && !isFormData) {
    options.body = JSON.stringify(options.body);
  }

  if (options.query) {
    const query = omitBy(options.query, isUndefined);
    qs = `?${queryString.stringify(query)}`;
  }
  options = Object.assign({ credentials: 'include' }, options);
  if (!isFormData) {
    options.headers = {
      'Content-Type': 'application/json',
      ...options.headers,
    };
  }
  return handledFetch(`${config.API_URL}${path}${qs}`, options)
    .then((res) => {
      if (res.status === 200) {
        return res.json();
      }
      return true;
    });
}

export async function fetchRetry(url, options = {}, backoffms = 1000, backoffMultiple = 1.6, retries = 3, retryCallback) {
  const retryCodes = [408, 500, 502, 503, 504, 522, 524];

  // dont love it as a while true, but disliked it more as a for loop
  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      // eslint-disable-next-line no-await-in-loop
      const res = await fetch(url, options);
      if (res.ok) return res;
      if (retries === 0 || !retryCodes.includes(res.status)) return res;
    } catch (err) {
      if (retries === 0) throw err;
    }
    // if somehow we got out of there
    if (retries === 0) throw new Error('failed to fetchRetry');

    if (retryCallback) retryCallback();

    // eslint-disable-next-line no-await-in-loop, no-loop-func
    await new Promise((resolve) => {
      setTimeout(resolve, backoffms);
    });

    backoffms *= backoffMultiple;
    retries--;
  }
}

export async function sendImages(images, imgWidth, imgHeight, timestamp, fetchUrl, imgType = 'image/png', imgQuality = 0.9, retryCallback) {
  const canvas = document.createElement('canvas');
  canvas.width = images[0].imgData.width;
  canvas.height = images[0].imgData.height;
  const ctx = canvas.getContext('2d', { willReadFrequently: true });

  const form = new FormData();

  for (let i = 0; i < images.length; i++) {
    const imgData = images[i].imgData;
    ctx.putImageData(imgData, 0, 0);
    const options = { type: imgType };

    if (imgType === 'image/jpeg') {
      options.quality = imgQuality;
    }

    // eslint-disable-next-line no-await-in-loop
    const blob = await new Promise((resolve) => {
      canvas.toBlob((blob) => {
        resolve(blob);
      }, options.type, options.quality);
    });
    form.append('images', blob, `${images[i].timestamp}.${imgType.split('/')[1]}`);
  }

  // at 1.3 10 retries should recover from ~40 seconds of outages
  return fetchRetry(fetchUrl, { body: form, method: 'post', credentials: 'include' }, 1000, 1.3, 10, retryCallback);
}

export async function saveMetaData(meta, userId, timestamp, retryCallback) {
  const blob = new Blob([JSON.stringify(meta, null, 2)], { type: 'application/json' });
  const fd = new FormData();
  fd.append('timestamp', Date.now());
  fd.append('image', blob, 'metadata.json');
  // should recover from 10 seconds of outages
  return fetchRetry(`${config.API_URL}/users/${userId}/vital_images/${timestamp}`, { body: fd, method: 'post', credentials: 'include' }, 1000, 1.3, 10, retryCallback);
}
