import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { browserHistory } from 'react-router';
import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

import AppBar from '../components/app-bar';
import { colors, desktopMaxWidth, fontSizing } from '../lib/styles';

const styles = {
  header: {
    fontSize: fontSizing.h3,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  linearProgress: {
    background: colors.lightBrightBlue,
  },
  marginBottom20: {
    marginBottom: 20,
  },
  marginTop20: {
    marginTop: 20,
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
  listItem: {
    '& div + div': {
      marginBottom: 20,
    },
  },
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: colors.white,
  },
  pageContent: {
    boxSizing: 'border-box',
    fontSize: fontSizing.body,
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
    paddingBottom: 10,
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 20,
    width: '100%',
  },
  pageContentContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    overflowX: 'scroll',
    backgroundColor: colors.white,
  },
  scrollContainer: {
    overflowY: 'scroll',
    backgroundColor: colors.white,
  },
  scrollHorizontal: {
    overflowX: 'scroll',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    overFlowY: 'scroll',
    backgroundColor: colors.white,
  },
};

class CoreVitalTermsAndConditions extends React.Component {
  render() {
    const { classes, router } = this.props;

    return (
      <div className={classes.pageWrapper}>
        <AppBar
          backButtonOnClick={() => router.goBack()}
          headerNode="User Manual"
          rightNode={(
            <IconButton onClick={() => browserHistory.push('/core-vital-user-manual-menu')}>
              <MenuIcon fontSize="large" />
            </IconButton>
          )}
        />
        <div className={classes.pageContent}>
          <div className={classes.header}>
            INFORMED VITAL CORE™ APPLICATION TERMS AND CONDITIONS
          </div>
          <p><strong>PLEASE READ THIS POLICY CAREFULLY BEFORE USING THE INFORMED VITAL CORE APPLICATION.</strong></p>
          <p><strong>Effective Date:</strong> March 17, 2024</p>
          <p>The Informed Vital Core Application (“IVC App”) is a Software as a Medical Device application developed by Mindset Medical (“we”, “us”, or “our”). The IVC App is designed to measure your pulse rate using the camera on your smart-phone, tablet, laptop, or desktop computer (“Device”). After taking your pulse rate, the IVC App will send your results to your healthcare provider. Access to the IVC App is through an Internet browser, requires access to your Messaging App, and does not include the transfer or license of any software to you.</p>
          <p><strong>PLEASE READ THE TERMS AND CONDITIONS (“Terms”) CAREFULLY. BY DOWNLOADING, INSTALLING, OR OTHERWISE ACCESSING OR USING THE APP, YOU (“YOU OR “YOUR”) AGREE THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS.</strong></p>
          <p>The IVC App is licensed by your healthcare provider, not sold to you, and you may use the IVC App only as set forth in these Terms.</p>
          <ol>
            <li className={classes.listItem}>
              <div><strong>We Do Not Provide Medical Advice</strong></div>
              <div><strong>THE IVC APP SHOULD NOT BE USED DURING A MEDICAL EMERGENCY OR FOR THE DIAGNOSIS OR TREATMENT OF ANY MEDICAL CONDITION. CALL 911 OR YOUR DOCTOR FOR ALL MEDICAL EMERGENCIES.</strong> Please consult your doctor or another qualified healthcare provider if you have any questions about your medical condition. Do not ignore or delay obtaining professional medical advice because of information provided through the IVC App.</div>
              <div>THE IVC APP CANNOT AND IS NOT DESIGNED, INTENDED OR APPROPRIATE TO REPLACE THE RELATIONSHIP YOU HAVE WITH YOUR HEALTHCARE PROVIDER(S) OR TO ADDRESS SERIOUS, EMERGENCY, OR LIFE-THREATENING MEDICAL CONDITIONS AND SHOULD NOT BE USED IN THOSE CIRCUMSTANCES. YOU SHOULD ALWAYS TALK TO YOUR HEALTHCARE PROVIDER(S) FOR DIAGNOSIS AND TREATMENT OF YOUR CONDITIONS.</div>
              <div>WE DO NOT RECOMMEND, REFER, ENDORSE, VERIFY, EVALUATE OR GUARANTEE ANY ADVICE, INFORMATION, TREATMENT, INSTITUTION, PRODUCT, PHYSICIAN, SURGEON, OPINION OR OTHER INFORMATION OR SERVICES PROVIDED BY ANY HEALTHCARE PROFESSIONAL USING THE IVC APP, AND NOTHING SHALL BE CONSIDERED AS OUR REFERRAL, ENDORSEMENT, RECOMMENDATION OR GUARANTEE OF ANY MEDICAL PROFESSIONAL OR COURSE OF TREATMENT.</div>
              <div>WE DO NOT WARRANT VALIDITY, ACCURACY, COMPLETENESS, SAFETY, LEGALITY, QUALITY, OR APPLICABILITY OF THE CONTENT OR ANYTHING SAID OR WRITTEN BY ANY PHYSICIAN OR ANY ADVICE PROVIDED. WE WILL NOT BE LIABLE FOR ANY DAMAGES SUSTAINED DUE TO RELIANCE BY YOU ON SUCH INFORMATION OR ADVICE PROVIDED BY ANY HEALTHCARE PROVIDER IN CONNECTION WITH THE USE OF THIS APP. WE ARE NOT LIABLE IN ANY WAY FOR ANY MALPRACTICE OR SUBSTANDARD TREATMENT YOUR PROVIDER MAY PROVIDE. YOU ARE USING THE SERVICES AT YOUR OWN RISK.</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>Your Use of App</strong></div>
              <div>You must be at least 22 years old to use the IVC App.</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>Device, Browser Access, and Internet Service</strong></div>
              <div>You must have Internet access to use the IVC App. You are responsible for the data security of your Device used to operate the IVC App by enabling biometric security measures or a passcode on your Device (i.e., touch ID, Face ID, PIN, or password). You are responsible for safeguarding your Device. You are responsible for using a safe method of internet services (personal wi-fi not public, your cellular network, and not using the IVC App in a public place).</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>SMS Text Messaging</strong></div>
              <div>You must be capable of receiving a text or SMS message on your Device to use the IVC App. Your mobile operator may charge standard and other text messaging fees for messages. Neither we nor any mobile network operator shall be liable for delayed or undelivered messages.</div>
              <div>Do not respond to text messages with personal health information. Such messages will not reach your healthcare professional. Call your healthcare professional directly to communicate with them.</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>Disclaimer of Warranties</strong></div>
              <div>YOU EXPRESSLY UNDERSTAND AND AGREE THAT: YOUR USE OF THE APP IS AT YOUR SOLE RISK. THE IVC APP IS PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS. MINDSET MEDICAL AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, AND LICENSORS EXPRESSLY DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NON-INFRINGEMENT. MINDSET MEDICAL AND ITS SUBSIDIARIES, AFFILIATES, OFFICERS, EMPLOYEES, AGENTS, AND LICENSORS MAKE NO WARRANTY THAT (i) THIS APP WILL BE UNINTERRUPTED, TIMELY, SECURE OR ERROR-FREE; (ii) THE RESULTS THAT MAY BE OBTAINED WILL BE ACCURATE OR RELIABLE; (iii) ANY ERRORS IN THE IVC APP WILL BE CORRECTED.</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>Indemnification</strong></div>
              <div>You agree to indemnify, defend, and hold harmless Mindset Medical, its clients, its suppliers, their respective affiliates, and all respective employees, officers, directors, agents, servants, shareholders, and representatives of each from any liability, loss, claim, suit, damage, and expense (including reasonable attorneys' fees and expenses) related to (i) Your violation of these Terms and (ii) Your use, or misuse, of the IVC App.</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>Force Majeure.</strong></div>
              <div>We shall not be liable for any actions or failure to act due to causes beyond its reasonable control, or due to “acts of God”.</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>Right to Change Terms</strong></div>
              <div>We may amend these Terms at any time.  Any changes to these Terms will be effective immediately upon posting of the changed Terms in the IVC App.  Any use of the IVC App following such changes constitutes your agreement to be bound by the new Terms.</div>
            </li>
            <li className={classes.listItem}>
              <div><strong>Miscellaneous</strong></div>
              <div>If any part of these Terms shall be determined to be invalid, illegal, or unenforceable by any valid act of any legislature or by any regulation duly promulgated or declared null and void by any court of competent jurisdiction, then such part shall be reformed, if possible, to conform to the law and, in any event, the remaining parts of these Terms shall be fully effective and operative insofar as reasonably possible.</div>
            </li>
            <li>
              <div><strong>Contact Information</strong></div>
              <div>Questions or comments regarding these Terms should be directed to Mindset Medical Using the following contact information:</div>
              <div className={classes.scrollHorizontal}>
                <address className={classes.marginTop20}>
                  <div>Postal Address:</div>
                  <div>Mindset Medical, LLC</div>
                  <div>12439 N 32nd St.</div>
                  <div>Phoenix, AZ 85032</div>
                </address>
                <address className={classes.marginTop20}>
                  <div>Attention: <span className={classes.noWrap}>Customer Service</span></div>
                  <div>Email: <span className={classes.noWrap}><a href="mailto:ivcsupport@mindsetmedical.com">ivcsupport@mindsetmedical.com</a></span></div>
                  <div>Telephone: <span className={classes.noWrap}><a href="tel:4803823170">480-382-3170</a></span></div>
                </address>
              </div>
            </li>
          </ol>
        </div>
      </div>
    );
  }
}

CoreVitalTermsAndConditions.propTypes = {
  classes: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
};

export default withStyles(styles)(CoreVitalTermsAndConditions);
