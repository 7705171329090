import config from '../config';

let peer;

function onsignalingstatechange (state) {
  console.info('answerer signaling state change:', state);
}

function oniceconnectionstatechange(event, me) {
  const state = peer.iceConnectionState;
  if (state === 'failed' || state === 'disconnected') {
    me.setShowPoorConnectionMessage(true);
  } else if (state === 'connected') {
    me.setShowPoorConnectionMessage(false);
  }
}

function onicegatheringstatechange (state) {
  console.info('answerer ice gathering state change:', state);
}

export async function startVideo (me, constraints = {}, micOn = true) {
  const mediaConstraints = { ...{
    video: {
      facingMode: "user",
      width: { exact: 640 },
      height: { exact: 480 }
    },
    audio: true,
    whiteBalanceMode: 'none',
    exposureMode: 'none',
  }, ...constraints};
  const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
  const videoTrack = stream.getVideoTracks()[0];
  const audioTrack = stream.getAudioTracks()[0];
  me.videoTrack = videoTrack;
  me.audioTrack = audioTrack;
  me.stream = stream;

  if (!micOn) me.audioTrack.enabled = false;
  return stream;
}

export async function startRearVideo(me, type = null, micOn = true) {
  const mediaConstraints = {
    video: {
      facingMode: 'environment',
      width: { exact: type !== null ? 640 : 320 },
      height: { exact: type !== null ? 480 : 240 },
      advanced: [
        { whiteBalanceMode: type === 'spo2' ? 'manual' : 'continuous' },
        { exposureMode: type === 'spo2' ? 'manual' : 'none' },
      ],
    },
    audio: type !== null,
  };

  const stream = await navigator.mediaDevices.getUserMedia(mediaConstraints);
  const videoTrack = stream.getVideoTracks()[0];
  const audioTrack = stream.getAudioTracks()[0];
  if (type === 'chat') {
    me.videoTrack = videoTrack;
    me.audioTrack = audioTrack;
    me.stream = stream;
    if (!micOn) me.audioTrack.enabled = false;
  } else {
    me.rearVideoTrack = videoTrack;
    me.rearStream = stream;
  }

  return stream;
}

export default async function createPeer (callRequest, me) {
  if(callRequest.iceServers) {
    config.rtc.cfg.iceServers = callRequest.iceServers;
  }
  peer = new RTCPeerConnection(config.rtc.cfg, config.rtc.con);
  me.peer = peer;

  if(me.videoTrack) {
    peer.addTrack(me.videoTrack, me.stream);
  }
  if(me.audioTrack) {
    peer.addTrack(me.audioTrack, me.stream);
  }

  peer.ondatachannel = function (e) {
    const datachannel = me.datachannel = e.channel || e; // Chrome sends event, FF sends raw channel

    datachannel.onopen = function (e) {
      datachannel.send(JSON.stringify({message: 'hello from answerer!'}));
      me.events.emit('peer_connected', {});

    }
    datachannel.onmessage = function (e) {
      if (e.data.charCodeAt(0) === 2) {
        // The first message we get from Firefox (but not Chrome)
        // is literal ASCII 2 and I don't understand why -- if we
        // leave it in, JSON.parse() will barf.
        return;
      } 
    }
  }

  peer.onsignalingstatechange = onsignalingstatechange;
  peer.oniceconnectionstatechange = (event) => {
    oniceconnectionstatechange(event, me);
  };
  peer.onicegatheringstatechange = onicegatheringstatechange;

  peer.onaddstream = (e) => {
    me.events.emit('gotStream', e.stream);
  }

  peer.ontrack = (e) => {
    me.events.emit('gotTrack', e.streams[0]);
  }

  peer.onconnection = (e) => {
    // console.log('peer onconnect', e);
  }

  return peer;

}
