import { createMuiTheme } from '@material-ui/core/styles';

export const colors = {
  primaryBlue: '#0c63aa',
  secondaryBlue: '#4e98cd',
  tertiaryBlue: '#c3dbf0',
  primaryYellow: '#f99f3c',
  secondaryYellow: '#ffcc80',
  tertiaryYellow: '#fff1dd',
  primaryOrange: '#fd6120',
  secondaryOrange: '#feb08f',
  tertiaryOrange: '#fecfbc',
  primaryGreen: '#3aa350',
  secondaryGreen: '#8fce72',
  tertiaryGreen: '#ebfade',
  primaryIndigo: '#53477f',
  secondaryIndigo: '#7f77a5',
  tertiaryIndigo: '#eeeaff',
  disabledGrayLight: '#d1d4db',
  disabledGrayDark: '#98a5b8',
  notificationGreen: '#42c678',
  notificationRed: '#ff403b',
  charcoalText: '#333333',
  white: '#fff',
  black: '#000',
};

export const gradients = {
  blueGradient: `linear-gradient(0.25turn, ${colors.primaryBlue}, ${colors.secondaryBlue});`,
  yellowGradient: `linear-gradient(0.25turn, ${colors.primaryYellow}, ${colors.secondaryYellow});`,
  orangeGradient: `linear-gradient(0.25turn, ${colors.primaryOrange}, ${colors.secondaryOrange});`,
  greenGradient: `linear-gradient(0.25turn, ${colors.primaryGreen}, ${colors.secondaryGreen});`,
  indigoGradient: `linear-gradient(0.25turn, ${colors.primaryIndigo}, ${colors.secondaryIndigo});`,
  charcoalGradient: `linear-gradient(0.25turn, ${colors.darkGrayBlue}, ${colors.disabledGrayDark});`,
  blueToGreenGradient: `linear-gradient(0.25turn, ${colors.primaryBlue}, ${colors.primaryGreen});`,
  blueToIndigoGradient: `linear-gradient(0.25turn, ${colors.primaryBlue}, ${colors.primaryIndigo});`,
  notificationGreenGradient: `linear-gradient(0.25turn, ${colors.notificationGreen}, ${colors.secondaryGreen});`,
};

export const muiGlobalMedNextTheme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: {
      light: colors.tertiaryBlue,
      main: colors.primaryBlue,
    },
    secondary: {
      light: colors.tertiaryYellow,
      main: colors.primaryYellow,
    },
  },
  props: {
    MuiButton: {
      background: `linear-gradient(45deg, ${colors.primaryBlue}, ${colors.secondaryGreen})`,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        // background: `linear-gradient(45deg, ${colors.primaryBlue}, ${colors.secondaryGreen})`,
      },
    },
  },
});
