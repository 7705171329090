import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Page from './page';
import { updatePRO } from '../state/pro-forms';
import { colors, fontSizing } from '../lib/styles';
import { saveMetaData } from '../lib/fetch';
import { CameraBuddy } from '../lib/cameraBuddy';

const styles = {
  patientVideo: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
    display: 'none',
  },
  patientCanvas: {
    minHeight: '450px',
    height: '100%',
    maxWidth: 1000,
    objectFit: 'cover',
    transform: 'scaleX(-1)',
    width: '100%',
  },
  container: {
    backgroundColor: colors.white,
    height: '100%',
  },
  topSection: {
    backgroundColor: colors.white,
    height: '100%',
    position: 'relative',
  },
  imReadyButton: {
    background: colors.primaryColor,
    borderRadius: 5,
    borderWidth: 0,
    color: 'white',
    flexGrow: 1,
    fontSize: fontSizing.body,
    maxWidth: 450,
    padding: 10,
    '&:disabled': {
      background: '#d9d8d8',
    },
  },
  imReadyButtonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    padding: 20,
  },
  videoContainer: {
    margin: '0px auto',
    height: '100%',
    maxWidth: '540px',
    width: '100%',
  },
  nonVideoContentContainer: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  text: {
    fontSize: '2rem',
  },
  nextBtn: {
    textAlign: 'center',
    width: '100%',
    top: '80%',
  },
};

const TARGET_FPS = 30;

class IvcPrClinicalStudy1 extends Page {
  constructor(props) {
    super(props);
    this.state = {
      failed: false,
      cameraError: '',
      cameraObject: {},
      finishedRecording: false,
      startTimestamp: null,
      timestamps: null,
      imgEncoding: 'image/jpeg',
    };

    this.ctx = null;
    this.patientCanvas = React.createRef();
    this.patientVideo = React.createRef();
    this.videoContainer = React.createRef();
  }

  handleContinue = async () => {
    const { updatePRO, userId, location: { query } } = this.props;

    const meta = {
      supportedConstraints: navigator.mediaDevices.getSupportedConstraints(),
      videoSettings: this.state.cameraObject.videoTrack.getSettings(),
      ua: navigator.userAgent,
      timestamps: this.state.timestamps,
    };

    let res;
    let error;
    try {
      res = await saveMetaData(meta, userId, this.state.startTimestamp);
    } catch (e) {
      error = e;
    }

    if (error || !res.ok) {
      console.error('saveMetaData failed: ', error, res);
      return this.setState({ failed: true });
    }

    updatePRO({
      type: 'vitalCore',
      position: this.getTrackIndex(),
      value: { ...this.state },
    });

    this.cameraBuddy.cleanup();
    this.forwardWithQuery(query);
  }

  componentDidMount = async () => {
    const { userId } = this.props;
    const { imgEncoding } = this.state;

    const cameraBuddy = new CameraBuddy(this.patientVideo, userId, false, imgEncoding, 0.95, 1, TARGET_FPS);
    this.cameraBuddy = cameraBuddy;
    try {
      const cameraObject = await this.cameraBuddy.startCamera();
      this.setState({ cameraObject });
    } catch (err) {
      console.log({ err })
      return this.setState({ failed: true, cameraError: err });
    }

    // set up our canvas
    const canvas = this.patientCanvas.current;
    canvas.height = this.patientVideo.current.videoHeight;
    canvas.width = this.patientVideo.current.videoWidth;
    this.ctx = canvas.getContext('2d', { willReadFrequently: true });

    this.cameraBuddy.on('frame', (patientVideoCurrent, pvW, pvH) => {
      // draw the camera image in our canvas
      this.ctx.drawImage(patientVideoCurrent, 0, 0, pvW, pvH);
    });

    this.cameraBuddy.on('finishedSaving', async () => {
      if (!this.state.failed) {
        await this.handleContinue();
      }
    }, { once: true });

    this.cameraBuddy.on('saveRequested', (saveRequested, timestamp) => {
      if (!this.state.startTimestamp) {
        this.setState({ startTimestamp: timestamp });
      }
    });

    this.cameraBuddy.on('failedCount', (failedCount, status, error) => {
      console.error('lost batch of frames', failedCount, status, error);
      return this.setState({ failed: true });
    });

    this.cameraBuddy.on('finishedRecording', async (timestamps) => {
      this.setState({ timestamps, finishedRecording: true });
    }, { once: true });
  }

  render() {
    const { classes } = this.props;
    const {
      cameraError,
    } = this.state;
    return (
      <div className={classes.container}>
        <section className={classes.topSection}>
          <div id="videoContainer" className={classes.videoContainer} ref={this.videoContainer} >
            <video id="patientVideo" ref={this.patientVideo} playsInline autoPlay className={classes.patientVideo} muted />
            <canvas id="patientCanvas" ref={this.patientCanvas} className={classes.patientCanvas} />
          </div>
          <div className={classes.nonVideoContentContainer}>
            <div className={classes.imReadyButtonWrapper}>
              <button
                className={classes.imReadyButton}
                onClick={async () => { await this.cameraBuddy.startSaving(); }}
                type="button"
                disabled={!this.cameraBuddy || !this.state.cameraObject}
              >
                Preview
              </button>
            </div>
            {cameraError ? (
              <div><span>Unable to access camera: {cameraError.toString()}</span><br /></div>
            ) : ''}
          </div>
        </section>

        <Snackbar
          open={this.state.failed}
          autoHideDuration={7000}
          onClose={async () => {
            this.goToIndexWithQuery(0, this.props.location.query);
          }}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MuiAlert elevation={6} variant="filled" severity="error" style={{ fontSize: 16 }}>There has been an error. The session will restart.</MuiAlert>
        </Snackbar>

      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state;

  return { user, userId: user.id };
}

IvcPrClinicalStudy1.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(IvcPrClinicalStudy1));
