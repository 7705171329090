import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import LinearProgress from '@material-ui/core/LinearProgress';
import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';

import { withStyles } from '@material-ui/core/styles';

import AppBar from '../components/app-bar';
import Continue from '../components/continue';

import { WeightScale } from '../lib/icons';

import Page from './page';
import { desktopMaxWidth } from '../lib/styles';
import { updatePRO } from '../state/pro-forms';

const styles = {
  formControlItem: {
    fontSize: '1.75rem',
    textAlign: 'center',
    margin: '1rem',
  },
  height: {
    display: 'inline-flex',
    justifyContent: 'space-between',
  },
  iconContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 0,
    width: '100%',
    color: '#000',
    paddingTop: 10,
  },
  iconWrapper: {
    width: 100,
  },
  pageContent: {
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: desktopMaxWidth,
  },
  text: {
    fontSize: '1.75rem',
  },
  nextBtn: {
    padding: 20,
  },
  weight: {
    textAlign: 'center',
  },
  wrapper: {
    width: '80%',
    overflowX: 'hidden',
    position: 'relative',
    padding: '20px',
  },
};

const StyledInput = withStyles(() => ({
  input: {
    textAlign: 'center',
  },
}))(Input);

class VitalCoreBenchHrBmi extends Page {
  constructor(props) {
    super(props);

    this.state = {
      feet: '',
      inches: '',
      weight: '',
      age: '',
    };
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  handleContinue = async () => {
    const { updatePRO, location: { query } } = this.props;
    updatePRO({
      type: 'vitalCoreBenchHr',
      position: this.getTrackIndex(),
      value: this.state });
    this.forwardWithQuery(query);
  }

  render() {
    const { feet, inches, weight, age } = this.state;
    const { classes } = this.props;

    return (
      <Fragment>
        <AppBar
          headerNode="BMI"
          noLeftNode={true}
        />
        <LinearProgress
          variant="determinate"
          value={(this.getCurrentPage() / this.getTotalPages()) * 100}
        />
        <div className={classes.pageContent}>
          <div className={classes.iconContainer}>
            <div className={classes.iconWrapper}>
              <WeightScale />
            </div>
          </div>
          <div className={classes.wrapper}>
            <p className={classes.text}>What is your current age?</p>
            <div className={classes.weight}>
              <FormControl className={classes.formControlItem}>
                <StyledInput
                  id="age-adornment"
                  type="tel"
                  value={age}
                  name="age"
                  placeholder="000"
                  onChange={this.handleChange}
                  inputProps={{
                    'aria-label': 'age',
                  }}
                />
              </FormControl>
              <Typography>years</Typography>
            </div>
            <p className={classes.text}>What is your current height and weight?</p>
            <p className={classes.text}>Height?</p>
            <div className={classes.height}>
              <FormControl className={classes.formControlItem}>
                <StyledInput
                  id="feet-adornment"
                  type="tel"
                  value={feet}
                  name="feet"
                  placeholder="00"
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">ft</InputAdornment>
                    }
                  aria-describedby="feet-helper-text"
                  inputProps={{
                    'aria-label': 'feet',
                  }}
                />
              </FormControl>
              <FormControl className={classes.formControlItem}>
                <StyledInput
                  id="inches-adornment"
                  type="tel"
                  value={inches}
                  name="inches"
                  placeholder="00"
                  onChange={this.handleChange}
                  endAdornment={
                    <InputAdornment position="end">in</InputAdornment>
                    }
                  aria-describedby="inches-helper-text"
                  inputProps={{
                    'aria-label': 'inches',
                  }}
                />
              </FormControl>
            </div>

            <p className={classes.text}>Weight?</p>
            <div className={classes.weight}>
              <FormControl className={classes.formControlItem}>
                <StyledInput
                  id="weight-adornment"
                  type="tel"
                  value={weight}
                  name="weight"
                  placeholder="000"
                  onChange={this.handleChange}
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                />
              </FormControl>
              <Typography>lbs</Typography>
            </div>
          </div>

          <div className={classes.nextBtn}>
            <Continue
              disabled={!feet || !inches || !weight || !age}
              text="Next"
              onClick={this.handleContinue}
              btnStyle={{ width: '100%' }}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

function mapStateToProps(state) {
  const { user, proForms: { vitalCoreBenchHr } } = state;
  return { user, vitalCoreBenchHr };
}

VitalCoreBenchHrBmi.proptypes = {
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  updatePRO: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { updatePRO })(withStyles(styles)(VitalCoreBenchHrBmi));
