import { combineReducers } from 'redux';

import PRO from './pro-forms';
import user from './user';
import errorScreens from './error-screens';

const { proForms, proSubmit } = PRO;

const reducers = combineReducers({
  errorScreens,
  proForms,
  proSubmit,
  user,
});

export default reducers;
