import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import brand from '../lib/brand';

const styles = {
  logoContainer: {
    height: 120,
    padding: '15px 60px',
    textAlign: 'center',
    maxWidth: '300px',
    margin: 'auto',
  },
  logo: {
    maxWidth: '300px',
  }
};

const Logo = ({ classes }) => (
  <div className={classes.logoContainer}>
    <img className={classes.logo} src={`/img/${brand.name}/${brand.loginLogo}`} alt={`${brand.title} Logo`} />
  </div>
);

export default withStyles(styles)(Logo);
