import { makeActionCreator, resetReducer } from 'cooldux';

export const setErrorScreenData = makeActionCreator('errorScreenData', 'errorScreens');

const initialState = {
  header: '',
  hideRetry: false,
  icon: null,
  messageOne: '',
  messageTwo: '',
  pageTitle: '',
  forVitalCore: false,
  onRetry: null,
};

const errorScreens = resetReducer(initialState, (state = initialState, { type, payload }) => {
  switch (type) {
    case setErrorScreenData.type:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
});

export default errorScreens;
