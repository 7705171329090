import { browserHistory } from 'react-router';

import store from '../store';
import { clearPros } from '../state/pro-forms';

const handleProInNewTab = (e) => {
  if (e.key === 'newTabOpened') {
    store.dispatch(clearPros());
    browserHistory.push('/session-conflict');
  }
};

export const addProInNewTabListener = () => {
  window.addEventListener('storage', handleProInNewTab);
};

export const emitProInNewTab = () => {
  window.localStorage.setItem('newTabOpened', new Date().getTime());
};

export const removeProInNewTabListener = () => {
  window.removeEventListener('storage', handleProInNewTab);
};

export const removeNewTabOpened = () => {
  window.localStorage.removeItem('newTabOpened');
};
